import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import changeLanguage from "../../utils/changeLanguage";
import getFullLanguageName from "../../utils/getFullLanguageName";
import { useNavigate } from "react-router-dom";
import { LOGOUT_URL } from "../../api";

const UserAvatarDropdownMenu = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <Dropdown.Menu align={"end"}>
      <Dropdown.Item onClick={() => navigate("/dashboard")}>
        {t("dashboard.title")}
      </Dropdown.Item>
      <Dropdown.Item onClick={() => navigate("/commands")}>
        {t("navbar.commands")}
      </Dropdown.Item>
      <Dropdown
        className="mb-3"
        as={Dropdown.Item}
        drop="start"
        autoClose="outside"
      >
        <Dropdown.Toggle variant="primary" as="div">
          {getFullLanguageName(i18n.language)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => changeLanguage("en")}>
            {i18n.t("languages.en")}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage("pl")}>
            {i18n.t("languages.pl")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown.Divider />
      <Dropdown.Item href={LOGOUT_URL} className="text-danger fw-bold">
        {t("logout")}
      </Dropdown.Item>
    </Dropdown.Menu>
  );
};

export default UserAvatarDropdownMenu;
