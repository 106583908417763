import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import WebsiteNavbar from "../components/navbar/Navbar";
import Footer from "../components/basic/Footer";
import Title from "../components/basic/Title";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import imageBackground from "../assets/img/background.jpg";

export default function Giveaway() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [endTime, setEndTime] = useState(
    convertFromSeconds(queryParams.endTime - Math.floor(Date.now() / 1000))
  );

  function convertFromSeconds(allSeconds) {
    const days = Math.floor(allSeconds / 86400);
    const rhours = allSeconds % 86400;
    const hours = Math.floor(rhours / 3600);
    const rsec = allSeconds % 3600;
    const minutes = Math.floor(rsec / 60);
    const seconds = rsec % 60;

    let endText = "";
    if (days > 0) {
      endText += `${days} ${t("timeFormat.days")} `;
    }
    if (hours > 0) {
      endText += `${hours} ${t("timeFormat.hours")} `;
    }
    if (minutes > 0) {
      endText += `${minutes} ${t("timeFormat.minutes")} `;
    }
    endText += `${Math.max(seconds, 0)} ${t("timeFormat.seconds")}`;

    return endText;
  }

  useEffect(() => {
    setInterval(() => {
      setEndTime(
        convertFromSeconds(queryParams.endTime - Math.floor(Date.now() / 1000))
      );
    }, 1000);
  });

  if (
    !queryParams.prize ||
    queryParams?.participants == null ||
    !queryParams?.createdBy ||
    !queryParams?.winnersCount ||
    !queryParams?.endTime
  ) {
    return <p className="m-2">Invalid Giveaway Parameters</p>;
  }

  return (
    <Title title={`${queryParams.prize} | Giveaway`}>
      <WebsiteNavbar />
      <Container className="mt-2">
        <h1 className="fw-bold">{queryParams.prize}</h1>
        {queryParams.description && <p>{queryParams.description}</p>}

        <Row className="d-flex justify-content-center text-center">
          <Col
            md={12}
            className="d-flex align-items-center justify-content-center giveawayThumbnail rounded vh-40 mb-5 mt-3"
            style={{
              backgroundImage: `url(${
                queryParams?.imageUrl ?? imageBackground
              })`,
              maxHeight: "400px",
            }}
          >
            <div className={`${queryParams?.imageUrl && "blur"} p-3 rounded`}>
              <h5>{t("dashboard.endsIn")}</h5>
              <h3 className="fw-bold">{endTime}</h3>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <h4>{t("dashboard.giveaways.participants")}</h4>
            <p className="fw-bold">
              {parseInt(queryParams.participants).toLocaleString()}
            </p>
          </Col>
          <Col md={6} lg={4}>
            <h4>{t("dashboard.giveaways.winnersCount")}</h4>
            <p className="fw-bold">
              {parseInt(queryParams.winnersCount).toLocaleString()}
            </p>
          </Col>
          <Col md={6} lg={4}>
            <h4>{t("dashboard.giveaways.createdBy")}</h4>
            <p className="fw-bold">{queryParams.createdBy}</p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Title>
  );
}
