import React from "react";
import Logo from "../../assets/img/nortBotLogo.webp";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FaDiscord, FaReddit } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import getFullLanguageName from "../../utils/getFullLanguageName";
import { Link } from "react-router-dom";
import { BACKEND_URL } from "../../api";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="bg-dark text-light mt-5">
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <img src={Logo} alt="NortBot Logo" height="25px" />
            <>
              <p className="fw-light pt-1">{t("nortbotText")}</p>
              <Dropdown className="mb-3">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {getFullLanguageName(i18n.language)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    {t("languages.en")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("pl")}>
                    {t("languages.pl")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          </Col>
          <Col xs={12} md={3}>
            <ul className="list-unstyled">
              <li>
                <h5 className="fw-bold">{t("footer.pages.text")}</h5>
              </li>
              <li>
                <Link to="/" className="text-decoration-none">
                  {t("footer.pages.home")}
                </Link>
              </li>
              <li>
                <Link to="/commands" className="text-decoration-none">
                  {t("footer.pages.commands")}
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <ul className="list-unstyled">
              <li>
                <h5 className="fw-bold">{t("footer.socialMedia.text")}</h5>
              </li>
              <li>
                <a
                  href={`${BACKEND_URL}/socials/Discord`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  Discord
                </a>
              </li>
              <li>
                <a
                  href={`${BACKEND_URL}/socials/TikTok`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  TikTok
                </a>
              </li>
              <li>
                <a
                  href={`${BACKEND_URL}/socials/X`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  X
                </a>
              </li>
              <li>
                <a
                  href={`${BACKEND_URL}/socials/Reddit`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  Reddit
                </a>
              </li>
              <li>
                <a
                  href={`${BACKEND_URL}/socials/Top.gg`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  Top.gg
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <ul className="list-unstyled">
              <li>
                <h5 className="fw-bold">{t("footer.rules.text")}</h5>
              </li>
              <li>
                <Link to="/privacy-policy" className="text-decoration-none">
                  {t("footer.rules.privacyPolicy")}
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-decoration-none">
                  {t("footer.rules.termsOfService")}
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <hr></hr>
        <Row className="mt-3 pb-3">
          <Col xs={12} md={9} className="text-md-start text-center">
            <p>
              &copy; {new Date().getFullYear()} NortBot.{" "}
              {t("footer.allRightsReserved")}
            </p>
          </Col>
          <Col xs={12} md={3}>
            <div className="d-flex justify-content-center justify-content-md-end">
              <a
                href={`${BACKEND_URL}/socials/Discord`}
                aria-label="Socials Discord"
                className="text-light me-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord />
              </a>
              <a
                href={`${BACKEND_URL}/socials/X`}
                aria-label="Socials X"
                className="text-light me-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter />
              </a>
              <a
                href={`${BACKEND_URL}/socials/Reddit`}
                aria-label="Socials Reddit"
                className="text-light"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaReddit />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
