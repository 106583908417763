import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/nortBotLogo.webp";
import { useTranslation } from "react-i18next";
import {
  Container,
  Nav,
  Navbar,
  Button,
  Dropdown,
  Offcanvas,
} from "react-bootstrap";
import useUser from "../../hooks/useUser";
import getUserAvatar from "../../utils/getUserAvatar";
import UserAvatarDropdownMenu from "../user/UserAvatarDropdownMenu";
import { LOGIN_URL, LOGOUT_URL } from "../../api";

const WebsiteNavbar = () => {
  const defaultBgColor = "#212529";
  const [navbarBg, setNavbarBg] = useState(defaultBgColor);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const expand = "lg";

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 30) {
      setNavbarBg("#212529dF");
    } else {
      setNavbarBg(defaultBgColor);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Navbar
      expand="sm"
      sticky="top"
      variant="dark"
      className="pt-2"
      style={{
        backgroundColor: isMenuOpen ? defaultBgColor : navbarBg,
        transition: "all 0.3s",
      }}
    >
      <Container>
        <Navbar.Brand
          onClick={() => navigate("/")}
          className="d-flex justify-content-center align-item-center"
        >
          <img src={Logo} alt="NortBot Logo" height="20px" className="mb-1" />
        </Navbar.Brand>
        <Navbar.Toggle
          className="border-0 focus-0"
          aria-controls="navbar-expand"
          onClick={handleMenuToggle}
        />

        <Navbar.Offcanvas
          id={`navbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-${expand}`}
              className="fw-bold"
            >
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="pt-0">
            <Nav className="me-auto">
              <Nav.Link as="button" onClick={() => navigate("/")}>
                {t("navbar.home")}
              </Nav.Link>
              <Nav.Link as="button" onClick={() => navigate("/commands")}>
                {t("navbar.commands")}
              </Nav.Link>
              <Nav.Link
                as="button"
                onClick={() => navigate("/dashboard")}
                className={!user && "d-none"}
              >
                {t("navbar.dashboard")}
              </Nav.Link>
            </Nav>
            {loading ||
              (!user ? (
                <Button
                  className="bg-primary-subtle subtle-primary-btn fw-medium d-block my-md-0 my-2"
                  variant="primary"
                  href={LOGIN_URL}
                >
                  {t("navbar.login")}
                </Button>
              ) : (
                <>
                  <Dropdown className="d-none d-sm-block" autoClose="outside">
                    <Dropdown.Toggle
                      as="img"
                      id="dropdown-basic"
                      src={getUserAvatar(user)}
                      width="40px"
                      className="rounded-circle"
                    ></Dropdown.Toggle>

                    <UserAvatarDropdownMenu />
                  </Dropdown>
                  <Button
                    as="a"
                    className="bg-danger-subtle subtle-danger-btn d-block d-sm-none fw-medium my-2"
                    variant="danger"
                    href={LOGOUT_URL}
                  >
                    {t("logout")}
                  </Button>
                </>
              ))}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default WebsiteNavbar;
