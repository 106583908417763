import React, { useEffect, useRef, useState } from "react";
import { Container, Card, Button, Col, Row } from "react-bootstrap";
import WebsiteNavbar from "../components/navbar/Navbar";
import Footer from "../components/basic/Footer";
import Title from "../components/basic/Title";
import useUser from "../hooks/useUser";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getGuildIcon from "../utils/getGuildIcon";
import getGuildBanner from "../utils/getGuildBanner";
import { RxUpdate } from "react-icons/rx";
import { AUTH_URL } from "../api";

function Dashboard() {
  const { loading, user, refreshGuilds } = useUser();
  const { t } = useTranslation();
  const [refreshingGuilds, setRefreshingGuilds] = useState(false);
  const guildsContainer = useRef(null);
  const refreshButton = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !loading) {
      return navigate("/");
    }
  });

  const calculateAngle = (object) => {
    const computedStyle = window.getComputedStyle(object);
    const matrix = computedStyle.transform;
    if (matrix !== "none") {
      const values = matrix.split("(")[1].split(")")[0].split(",");
      const a = values[0],
        b = values[1];
      let angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));

      if (angle < 0) angle += 360;

      return angle;
    }
    return 0;
  };

  const refreshGuildsButtonClicked = async () => {
    if (loading || refreshingGuilds) return;
    setRefreshingGuilds(true);
    const spinner = refreshButton.current.querySelector("svg");
    spinner.classList.add("spin");
    guildsContainer.current.classList.remove("puffAnimation");

    await refreshGuilds();

    guildsContainer.current.classList.add("puffAnimation");
    spinner.style.transform = `rotate(${calculateAngle(spinner)}deg)`;
    spinner.classList.remove("spin");
    setRefreshingGuilds(false);
  };

  if (loading) return;

  return (
    <Title title={t("navbar.dashboard")}>
      <WebsiteNavbar />
      <Container className="mt-2">
        <div className="text-center">
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-md-2">
            <h1 className="fw-bold w-fit">{t("dashboard.yourServers")}</h1>
            <Button
              ref={refreshButton}
              variant="transparent"
              className="border-0 refresh-guilds"
              onClick={refreshGuildsButtonClicked}
            >
              <RxUpdate size={30} />
            </Button>
          </div>
          <p className="fs-5">{t("dashboard.chooseServer")}</p>
        </div>
        <div ref={guildsContainer} className="puffAnimation">
          <Row className="d-flex flex-wrap justify-content-center puffAnimation gap-3">
            {user?.guilds?.map((guild) => (
              <Col
                xl={3}
                md={5}
                xs={12}
                key={guild.id}
                className="d-flex justify-content-center m-0 p-0"
              >
                <Card
                  className="p-2 w-100 mx-2 bg-dark-subtle"
                  style={{ width: "25rem" }}
                >
                  <Card.Img
                    variant="top"
                    height="85px"
                    className="rounded"
                    src={getGuildBanner(guild, 2048)}
                    style={{ objectFit: "cover" }}
                  />
                  <img
                    height="64px"
                    width="64px"
                    alt={guild.name}
                    src={getGuildIcon(guild, 1024)}
                    className="rounded-circle"
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "20px",
                      boxShadow: "0 0 0 2px",
                    }}
                  ></img>
                  <Card.Body className="d-flex flex-column">
                    <Card.Title>{guild.name}</Card.Title>
                    <Card.Text className="fw-light">
                      {guild.owner === true
                        ? t("dashboard.owner")
                        : t("dashboard.admin")}
                    </Card.Text>

                    <div className="mt-auto">
                      {guild.isNortBot ? (
                        <Button
                          variant="primary"
                          className="w-100"
                          onClick={() => navigate(`/dashboard/${guild.id}`)}
                        >
                          {t("dashboard.configurate")}
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          className="w-100"
                          href={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&permissions=8&scope=bot+applications.commands&guild_id=${guild.id}&redirect_uri=${AUTH_URL}/guilds&response_type=code`}
                        >
                          {t("dashboard.addToServer")}
                        </Button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}

            {user?.guilds?.length === 0 && (
              <h1 className="d-flex align-items-center justify-content-center vh-50">
                {t("dashboard.noServers")}
              </h1>
            )}
          </Row>
        </div>
      </Container>
      <Footer />
    </Title>
  );
}

export default Dashboard;
