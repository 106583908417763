import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useToast from "../../hooks/useToast";
import Status from "../../enums/Status";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { createItem, deleteItem, putItem } from "../../api";
const defaultItem = {
  name: "",
  description: "",
  cost: "",
  stockRemaining: "",
  sellable: true,
  returnable: false,
  requiredRole: null,
  receivedRole: null,
  removedRole: null,
};

const StoreModal = ({
  economyItem,
  guildId,
  show,
  hideModal,
  removeItem,
  updateItem,
  addItem,
  isNew,
  roles,
}) => {
  const [item, setItem] = useState(null);
  const { t } = useTranslation();
  const { showModal } = useConfirmationModal();
  const { showToast } = useToast();

  useEffect(() => {
    setItem(isNew ? defaultItem : economyItem);
  }, [economyItem, isNew]);

  const updateEconomyItem = async () => {
    try {
      const data = await putItem(item, item.id, guildId);
      if (data.status === "success") {
        updateItem(item);
        showToast(data.message, Status.Success);
        hideModal();
      } else {
        showToast("Error saving item", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error saving item",
        Status.Fail
      );
    }
  };

  const deleteEconomyItem = async () => {
    const data = await deleteItem(item.id, guildId);
    if (data?.status === "success") {
      showToast(data.message, Status.Success);
      removeItem(item.id);
    } else {
      showToast("Error deleting item", Status.Fail);
    }
    hideModal();
  };

  const createEconomyItem = async () => {
    try {
      const data = await createItem(item, guildId);
      if (data.status === Status.Success) {
        addItem({ ...item, id: data?.itemId });
        showToast(data.message, Status.Success);
        hideModal();
        setItem(defaultItem);
      } else {
        showToast("Error creating item", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error creating item",
        Status.Fail
      );
    }
  };

  const handleSetItemCost = (e) => {
    setItem((prev) => ({ ...prev, cost: e.target.value }));
  };

  const handleSetItemName = (e) => {
    setItem((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleSetItemStockRemaining = (e) => {
    setItem((prev) => ({ ...prev, stockRemaining: e.target.value }));
  };

  const handleSetItemDescription = (e) => {
    setItem((prev) => ({ ...prev, description: e.target.value }));
  };

  const handleSetItemSellable = (e) => {
    setItem((prev) => ({ ...prev, sellable: e.target.checked }));
  };

  const handleSetItemReturnable = (e) => {
    setItem((prev) => ({ ...prev, returnable: e.target.checked }));
  };

  const handleSetItemRequiredRole = (e) => {
    setItem((prev) => ({ ...prev, requiredRole: e.target.value }));
  };

  const handleSetItemReceivedRole = (e) => {
    setItem((prev) => ({ ...prev, receivedRole: e.target.value }));
  };

  const handleSetItemRemovedRole = (e) => {
    setItem((prev) => ({ ...prev, removedRole: e.target.value }));
  };

  if (!item) return;
  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        size="lg"
        centered
        scrollable
        animation
      >
        <Modal.Header>
          <Modal.Title>
            {t(`dashboard.economyStore.${isNew ? "create" : "edit"}`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="item.name">
                <Form.Control
                  type="text"
                  placeholder={t("dashboard.economyStore.item.itemName")}
                  size="lg"
                  maxLength={100}
                  value={item.name}
                  onChange={handleSetItemName}
                  className="border-0 ps-0 fw-bold"
                  style={{ outline: "none", boxShadow: "none" }}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="item.Description">
                <Form.Label>
                  {t("dashboard.economyStore.item.description")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={t(
                    "dashboard.economyStore.placeholders.description"
                  )}
                  rows={2}
                  maxLength={700}
                  value={item.description}
                  onChange={handleSetItemDescription}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="item.Cost">
                <Form.Label>{t("dashboard.economyStore.item.cost")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="1 000"
                  max={999999}
                  min={-999999}
                  maxLength={6}
                  value={item.cost}
                  onChange={handleSetItemCost}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="item.StockRemaining">
                <Form.Label>
                  {t("dashboard.economyStore.item.stockRemaining")}
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t(
                    "dashboard.economyStore.placeholders.infinity"
                  )}
                  max={999999}
                  min={-999999}
                  value={item.stockRemaining ?? null}
                  onChange={handleSetItemStockRemaining}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="item.Sellable">
                <Form.Label>
                  {t("dashboard.economyStore.item.sellable")}
                </Form.Label>
                <Form.Check
                  type="switch"
                  checked={item.sellable}
                  onChange={handleSetItemSellable}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="item.Returnable">
                <Form.Label>
                  {t("dashboard.economyStore.item.returnable")}
                </Form.Label>
                <Form.Check
                  type="switch"
                  checked={item.returnable}
                  onChange={handleSetItemReturnable}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="item.RequiredRole">
                <Form.Label>
                  {t("dashboard.economyStore.item.requiredRole")}
                </Form.Label>
                <Form.Select
                  value={item.requiredRole ?? 0}
                  onChange={handleSetItemRequiredRole}
                >
                  <option value={0}>{t("none")}</option>
                  {roles.map((role) => (
                    <option key={`option-role-${role.id}`} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="item.ReceivedRole">
                <Form.Label>
                  {t("dashboard.economyStore.item.receivedRole")}
                </Form.Label>
                <Form.Select
                  value={item.receivedRole ?? 0}
                  onChange={handleSetItemReceivedRole}
                >
                  <option value={0}>{t("none")}</option>
                  {roles.map((role) => (
                    <option
                      key={`option-received-role-${role.id}`}
                      value={role.id}
                    >
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="item.RemovedRole">
                <Form.Label>
                  {t("dashboard.economyStore.item.removedRole")}
                </Form.Label>
                <Form.Select
                  value={item.removedRole ?? 0}
                  onChange={handleSetItemRemovedRole}
                >
                  <option value={0}>{t("none")}</option>
                  {roles.map((role) => (
                    <option
                      key={`option-removed-role-${role.id}`}
                      value={role.id}
                    >
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        {!isNew ? (
          <Modal.Footer className="d-flex justify-content-between align-items-center">
            <span>#{item.id}</span>
            <div>
              <Button variant="secondary" className="mx-1" onClick={hideModal}>
                {t("modal.close")}
              </Button>
              <Button
                className="mx-1"
                variant="primary"
                onClick={() => showModal(updateEconomyItem)}
              >
                {t("modal.save")}
              </Button>
              <Button
                className="mx-1"
                variant="danger"
                onClick={() => showModal(deleteEconomyItem)}
              >
                {t("modal.delete")}
              </Button>
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button className="mx-1" variant="secondary" onClick={hideModal}>
              {t("modal.close")}
            </Button>
            <Button
              className="mx-1"
              variant="primary"
              onClick={() => showModal(createEconomyItem)}
            >
              {t("modal.create")}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default StoreModal;
