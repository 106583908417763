/**
 *
 * @param {string} emoji Discord Emoji String
 * @returns
 */
const getEmojiId = (emoji) => {
  const discordEmojiRegex = /^<(a?):(\w+):(\d+)>$/;
  const match = emoji?.match(discordEmojiRegex);

  console.log(match);
  if (match) {
   // if (match[1] == "a") return match[3] + ".gif";
    return match[3];
  }

  return emoji;
};

export default getEmojiId;
