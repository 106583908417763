import React from "react";
import { Container } from "react-bootstrap";
import WebsiteNavbar from "../components/navbar/Navbar";
import Footer from "../components/basic/Footer";
import Title from "../components/basic/Title";
import { useTranslation } from "react-i18next";

export default function Privacy() {
  const { t } = useTranslation();
  return (
    <Title title={t("privacyPolicy.header")}>
      <WebsiteNavbar />
      <Container className="mt-2">
        <h1 className="fw-bold">{t("privacyPolicy.header")}</h1>
        <p className="fw-medium">{t("privacyPolicy.headerP")}</p>

        <h2 className="fw-bold">{t("privacyPolicy.accessToData")}</h2>
        <p>{t("privacyPolicy.accessToDataP")}</p>

        <h2 className="fw-bold">{t("privacyPolicy.storageOfData")}</h2>
        <p>{t("privacyPolicy.storageOfDataP")}</p>

        <h2 className="fw-bold">{t("privacyPolicy.userRights")}</h2>
        <p>{t("privacyPolicy.userRightsP")}</p>

        <h2 className="fw-bold">{t("privacyPolicy.underageUsers")}</h2>
        <p>{t("privacyPolicy.underageUsersP")}</p>

        <h2 className="fw-bold">{t("privacyPolicy.questions")}</h2>
        <p>{t("privacyPolicy.questionsP")}</p>
      </Container>
      <Footer />
    </Title>
  );
}
