import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../spinners/LoadingSpinner";
import useToast from "../../hooks/useToast";
import Status from "../../enums/Status";
import {
  getLanguague,
  getPremium,
  patchLanguague,
  patchNickname,
} from "../../api";

const General = ({ guildId }) => {
  const [premium, setPremium] = useState(null);
  const [language, setLanguage] = useState("en");
  const nicknameInput = useRef(null);
  const languageInput = useRef(null);
  const { t } = useTranslation();
  const { showToast } = useToast();

  const changeNickname = async () => {
    try {
      const req = await patchNickname(
        nicknameInput.current.value || null,
        guildId
      );
      if (req.status === 200) {
        showToast(req.data.message, Status.Success);
      }
    } catch {
      showToast("Error changing nickname", Status.Fail);
    }
  };

  const changeLanguage = async () => {
    try {
      const req = await patchLanguague(languageInput.current.value, guildId);
      if (req.status === 200) {
        showToast(req.data.message, Status.Success);
      }
    } catch {
      showToast("Error changing language", Status.Fail);
    }
  };

  useEffect(() => {
    const fetchLanguage = async () => {
      const languageData = await getLanguague(guildId);
      setLanguage(languageData);
      const premium = await getPremium(guildId);
      setPremium(premium);
    };

    fetchLanguage();
  }, [guildId]);

  if (premium === null || !language) return <LoadingSpinner />;

  return (
    <>
      <Card className="m-2 bg-dark-subtle">
        <Card.Header className="bg-primary">
          <Card.Title className="m-0">
            {t("dashboard.general.settings.header")}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="mb-3">
            <h3>{t("dashboard.general.settings.nickname")}</h3>
            <p>{t("dashboard.general.settings.nicknameDescription")}</p>
            <InputGroup>
              <Form.Control
                placeholder="NortBot"
                aria-label="NortBot's display name"
                ref={nicknameInput}
                maxLength="32"
                name="nortbot-nickname"
              />
              <Button variant="outline-primary" onClick={changeNickname}>
                {t("dashboard.apply")}
              </Button>
            </InputGroup>
          </div>
          <div className="mb-3">
            <h3>{t("dashboard.general.settings.language")}</h3>
            <p>{t("dashboard.general.settings.languageDescription")}</p>
            <Form.Select
              ref={languageInput}
              onChange={changeLanguage}
              name="nortbot-language"
            >
              <option selected={language === "en"} value="en">
                {t("languages.en")}
              </option>
              <option selected={language === "pl"} value="pl">
                {t("languages.pl")}
              </option>
            </Form.Select>
          </div>
          <div>
            <h3>{t("dashboard.general.settings.premium")}</h3>
            {premium != null && (
              <p>
                {premium ? (
                  t("dashboard.general.settings.havePremiumDescription")
                ) : (
                  <>
                    {t("dashboard.general.settings.notHavePremiumDescription")}
                    <a
                      href={`${process.env.REACT_APP_PREMIUM_URL}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ms-1"
                    >
                      NortBot Premium
                    </a>
                  </>
                )}
              </p>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default General;
