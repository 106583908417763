import api from "../../api";

/**
 *
 * @param {number} guildId
 * @param {number} page
 * @param {number} limit
 * @returns
 */
export async function getUsers(guildId, page, limit) {
  const params = {};

  if (page != null) {
    params.page = page;
  }

  if (limit != null) {
    params.limit = limit;
  }

  const req = await api.get(`/guilds/${guildId}/economy/users`, {
    params,
  });
  return req.data;
}

export async function putUserMoney(amount, userId, guildId) {
  const req = await api.put(
    `/guilds/${guildId}/economy/users/${userId}/money`,
    {
      amount: amount,
    }
  );
  return req.data;
}

/**
 *
 * @param {number} amount
 * @param {[]} inventory
 * @param {number | string} userId
 * @param {number | string} guildId
 * @returns {Promise<AxiosResponse<any, any>>}
 */
export async function putUser(amount, inventory, userId, guildId) {
  const req = await api.put(`/guilds/${guildId}/economy/users/${userId}`, {
    user: {
      amount: amount,
      inventory: inventory,
    },
  });
  return req.data;
}

export async function deleteUser(userId, guildId) {
  const req = await api.delete(`/guilds/${guildId}/economy/users/${userId}`);
  return req.data;
}
