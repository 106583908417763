import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import truncateText from "../../utils/truncateText";

const InventoryItem = ({ name, quantity, removeItem, onQuantityChange }) => {
  const { t } = useTranslation();
  const [localQuantity, setLocalQuantity] = useState(quantity);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [name, quantity]);

  const changeQuantity = (e) => {
    const newQuantity = e.target.value;
    setLocalQuantity(newQuantity);

    if (onQuantityChange) {
      onQuantityChange(name, newQuantity);
    }
  };

  return (
    <ListGroup.Item key={`item-${name}`} variant="dark">
      <Row>
        <Col
          md={5}
          xs={5}
          className="d-flex align-items-center justify-content-start"
        >
          {truncateText(name, 50)}
        </Col>
        <Col
          md={7}
          xs={7}
          className="d-flex align-items-center justify-content-end"
        >
          <Form.Control
            style={{ maxWidth: "60px" }}
            className="me-2"
            size="sm"
            type="number"
            placeholder="1"
            max={999}
            min={1}
            value={localQuantity}
            onChange={changeQuantity}
          />
          <Button variant="danger" size="sm" onClick={() => removeItem(name)}>
            {t("modal.delete")}
          </Button>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default InventoryItem;
