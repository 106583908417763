import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useToast from "../../hooks/useToast";
import Status from "../../enums/Status";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { createReply, deleteReply, putReply } from "../../api";
const defaultReply = {
  content: "",
  status: Status.Success.toLocaleUpperCase(),
};

const ReplyModal = ({
  reply: economyReply,
  guildId,
  show,
  hideModal,
  removeReply,
  updateReply,
  addReply,
  isNew,
}) => {
  const { t } = useTranslation();
  const [reply, setReply] = useState(null);
  const { showModal } = useConfirmationModal();
  const { showToast } = useToast();

  useEffect(() => {
    if (isNew) setReply(defaultReply);
    else setReply(economyReply);
  }, [economyReply, isNew]);

  const updateEconomyReply = async () => {
    try {
      const data = await putReply(reply, reply.id, guildId);
      if (data.status === Status.Success) {
        updateReply(reply);
        showToast(data.message, Status.Success);
        hideModal();
      } else {
        showToast("Error saving reply", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error saving item",
        Status.Fail
      );
    }
  };

  const deleteEconomyReply = async () => {
    try {
      const data = await deleteReply(reply.id, guildId);
      if (data.status === Status.Success) {
        removeReply(reply.id);
        showToast(data.message, Status.Success);
        hideModal();
      } else {
        showToast("Error deleting reply", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error deleting reply",
        Status.Fail
      );
    }
  };

  const createEconomyReply = async () => {
    try {
      const data = await createReply(reply, guildId);
      if (data.status === Status.Success) {
        addReply({ ...reply, id: data?.replyId });
        showToast(data.message, Status.Success);
        hideModal();
        setReply(defaultReply);
      } else {
        showToast("Error creating reply", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error creating reply",
        Status.Fail
      );
    }
  };

  const handleSetReplyContent = (e) => {
    setReply((prev) => ({ ...prev, content: e.target.value }));
  };

  const handleSetReplyStatus = (e) => {
    setReply((prev) => ({ ...prev, status: e.target.value }));
  };

  if (!reply) return;
  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg" centered animation>
        <Modal.Header>
          <Modal.Title>
            {t(`dashboard.economyReplies.${isNew ? "create" : "edit"}`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="reply.Content">
                <Form.Label>{t("dashboard.economyReplies.content")}</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={t(
                    `dashboard.economyReplies.placeholders.${reply.status.toLowerCase()}Content`
                  )}
                  maxLength={500}
                  value={reply.content}
                  onChange={handleSetReplyContent}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="reply.Status">
                <Form.Label>{t("dashboard.economyReplies.status")}</Form.Label>
                <Form.Select
                  value={reply.status}
                  onChange={handleSetReplyStatus}
                >
                  <option value="SUCCESS">{t("status.success")}</option>
                  <option value="FAIL">{t("status.fail")}</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        {!isNew ? (
          <Modal.Footer className="d-flex justify-content-between align-items-center">
            <span>#{reply.id}</span>
            <div>
              <Button className="mx-1" variant="secondary" onClick={hideModal}>
                {t("modal.close")}
              </Button>
              <Button
                className="mx-1"
                variant="primary"
                onClick={() => showModal(updateEconomyReply)}
              >
                {t("modal.save")}
              </Button>
              <Button
                className="mx-1"
                variant="danger"
                onClick={() => showModal(deleteEconomyReply)}
              >
                {t("modal.delete")}
              </Button>
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button className="mx-1" variant="secondary" onClick={hideModal}>
              {t("modal.close")}
            </Button>
            <Button
              className="mx-1"
              variant="primary"
              onClick={() => showModal(createEconomyReply)}
            >
              {t("modal.create")}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ReplyModal;
