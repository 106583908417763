import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import CurrencyIcon from "./CurrencyIcon";

const UserItem = ({ user, currencyIconEmojiId, showUserModal }) => {
  const moneyFormated = user.money.toLocaleString();
  return (
    <ListGroup.Item
      action
      onClick={() => showUserModal(user)}
      key={`user-${user.id}`}
      variant="dark"
    >
      <Row>
        <Col
          md={6}
          xs="auto"
          className="d-flex align-items-center justify-content-start"
        >
          <img
            src={user.avatarUrl}
            alt={`Avatar ${user.username}`}
            className="rounded-circle me-2"
            style={{
              height: "2rem",
              verticalAlign: "middle",
            }}
          />
          <span>{user.displayName}</span>
        </Col>
        <Col
          md={6}
          xs="auto"
          className="d-flex align-items-center justify-content-end"
        >
          <span>
            {moneyFormated}
            <CurrencyIcon currencyIcon={currencyIconEmojiId} />
          </span>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default UserItem;
