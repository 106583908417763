import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import trustServers from "../data/trustServers.json";
import WebsiteNavbar from "../components/navbar/Navbar";
import Footer from "../components/basic/Footer";
import Feature from "../components/utils/Feature";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import FadeIn from "../components/effects/FadeIn";
import Title from "../components/basic/Title";
import TrustServer from "../components/basic/TrustServer";
import { BACKEND_URL } from "../api";
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";

function App() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUser();

  return (
    <Title title={t("navbar.home")}>
      <WebsiteNavbar />
      <Container className="mt-2">
        <div className="d-flex justify-content-center align-items-center flex-column my-4 vh-70 text-center">
          <FadeIn>
            <h1 className="fs-1 fw-bolder">{t("mainText")}</h1>
          </FadeIn>
          <FadeIn>
            <p className="fs-5 text-body-secondary">{t("mainDescription")}</p>
          </FadeIn>
          <FadeIn className="w-100vh">
            <Row className="w-100vh">
              <Col className="px-2 pb-2" md={12} lg={12}>
                <Button
                  className="bg-primary-subtle subtle-primary-btn fw-medium"
                  variant="primary"
                  size="lg"
                  href={`${BACKEND_URL}/invite`}
                  target="_blank"
                >
                  {t("addToServer")}
                </Button>
              </Col>
              <Col className="px-2" md={12} lg={12}>
                <Button
                  className="bg-secondary-subtle subtle-secondary-btn fw-medium"
                  variant="secondary"
                  size="lg"
                  href={`${BACKEND_URL}/userInstall`}
                  target="_blank"
                >
                  {t("installOnUser")}
                </Button>
              </Col>
              <Col sm={12} className={`${!user ? "d-none" : "d-block"}`}>
                <hr />
                <Button
                  className="bg-primary-subtle subtle-primary-btn fw-medium"
                  variant="primary"
                  size="lg"
                  onClick={() => navigate("/dashboard")}
                >
                  {t("navbar.dashboard")}
                </Button>
              </Col>
            </Row>
          </FadeIn>
        </div>
      </Container>
      <Container>
        <h5 className="text-center fw-light mb-4 fs-6">
          {t("home.serverThatTrustUs")}
        </h5>
        <Marquee speed="50">
          {trustServers.map((trustServer) => (
            <TrustServer
              key={trustServer.name}
              server={trustServer}
              iconUrl={trustServer.icon}
              serverName={trustServer.name}
              membersCount={trustServer.members}
              inviteChar={trustServer.invite}
            />
          ))}
        </Marquee>
      </Container>
      <Container className="pt-5">
        <FadeIn>
          <Feature
            icon="🎉"
            title={t("features.giveaway")}
            description={t("features.giveawayDescription")}
          />
        </FadeIn>
        <FadeIn>
          <Feature
            icon="📊"
            title={t("features.polls")}
            description={t("features.pollsDescription")}
            alignRight
          />
        </FadeIn>
        <FadeIn>
          <Feature
            icon="🧾"
            title={t("features.loggingSystem")}
            description={t("features.loggingSystemDescription")}
          />
        </FadeIn>
        <FadeIn>
          <Feature
            icon="🎊"
            title={t("features.4Fun")}
            description={t("features.4FunDescription")}
            alignRight
          />
        </FadeIn>
        <FadeIn>
          <Feature
            icon="💰"
            title={t("features.economy")}
            description={t("features.economyDescription")}
          />
        </FadeIn>
      </Container>
      <Container className="mb-5">
        <Row className="d-flex align-items-center">
          <Col sm={12} md={6} className="">
            <FadeIn>
              <h2 className="fw-bold">{t("supportServer.title")}</h2>
              <p>{t("supportServer.description")}</p>
            </FadeIn>
          </Col>
          <Col sm={12} md={6} className="d-flex justify-content-center">
            <iframe
              src="https://discord.com/widget?id=1036358454467633173&theme=dark"
              width="100%"
              height="450"
              frameBorder="0"
              allowtransparency="true"
              className="rounded"
              title="NortBotCommunityServer"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Title>
  );
}

export default App;
