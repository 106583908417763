import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Modal,
  Button,
  ListGroup,
  ListGroupItem,
  InputGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useToast from "../../hooks/useToast";
import Status from "../../enums/Status";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { deleteUser, putUser } from "../../api";
import InventoryItem from "./InventoryItem";

const EconomyUserModal = ({
  user,
  guildId,
  items,
  show,
  hideModal,
  removeUser,
}) => {
  const { t } = useTranslation();
  const [userMoney, setUserMoney] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [item, setItem] = useState({ itemName: 0, quantity: 1 });
  const addItemButton = useRef();
  const { showModal } = useConfirmationModal();
  const { showToast } = useToast();

  const handleSetUserMoney = (e) => setUserMoney(e.target.value);

  useEffect(() => {
    setUserMoney(user?.money);
    setInventory(user?.inventory || []);
    setItem({ itemName: 0, quantity: 1 });
  }, [user]);

  const updateEconomyUser = async () => {
    try {
      const data = await putUser(userMoney, inventory, user.id, guildId);
      if (data.status === "success") {
        user.money = userMoney;
        showToast(data.message, Status.Success);
        hideModal();
      } else {
        showToast("Error saving user", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg ||
          e?.response?.data?.error ||
          "Error saving user",
        Status.Fail
      );
    }
  };

  const deleteEconomyUser = async () => {
    try {
      const data = await deleteUser(user.id, guildId);
      if (data?.status === "success") {
        showToast(data.message, Status.Success);
        removeUser(user.id);
        hideModal();
      } else {
        showToast("Error deleting user", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error deleting user",
        Status.Fail
      );
    }
  };

  const removeItem = (itemName) => {
    setInventory((prev) => prev.filter((item) => item.itemName !== itemName));
  };

  const addItem = () => {
    if (
      item.itemName === 0 ||
      inventory.some((i) => i.itemName === item.itemName)
    )
      return;
    setInventory((prev) => (Array.isArray(prev) ? [...prev, item] : [item]));
    setItem({ itemName: 0, quantity: 1 });
  };

  const itemChange = (prop, value) => {
    setItem((prev) => ({ ...prev, [prop]: value }));
  };

  const onQuantityChange = (name, quantity) => {
    setInventory((prev) => {
      const existingItemIndex = prev.findIndex(
        (item) => item.itemName === name
      );

      if (existingItemIndex !== -1) {
        const updatedInventory = [...prev];
        updatedInventory[existingItemIndex].quantity = parseInt(quantity);
        return updatedInventory;
      } else {
        return [...prev, { itemName: name, quantity }];
      }
    });
  };

  if (!user) return;

  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        size="lg"
        centered
        animation
        scrollable
        backdrop={"static"}
      >
        <Modal.Body>
          <Row>
            <Col md={4} className="my-2">
              <img
                src={user.avatarUrl}
                alt={`Avatar ${user.username}`}
                className="rounded-circle"
                style={{
                  height: "5rem",
                  verticalAlign: "middle",
                }}
              />
            </Col>
            <Col
              md={12}
              className="d-flex align-items-center justify-content-space-around my-2 me-2"
            >
              <h4>{user.displayName}</h4>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="user.Money">
                <Form.Label>{t("dashboard.economyUsers.total")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="1 000"
                  max={999999999}
                  min={-999999999}
                  value={userMoney}
                  onChange={handleSetUserMoney}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <p className="mb-2">{t("dashboard.economyUsers.inventory")}</p>
              <ListGroup>
                {inventory.map((item) => (
                  <InventoryItem
                    name={item.itemName}
                    quantity={item.quantity}
                    removeItem={removeItem}
                    onQuantityChange={onQuantityChange}
                  />
                ))}
                {inventory.length === 0 && (
                  <ListGroupItem variant="dark">
                    {t("dashboard.economyStore.noItems")}
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
            <Col md={12} className="pt-3 d-flex">
              <InputGroup>
                <Button onClick={addItem} ref={addItemButton}>
                  {t("dashboard.economyUsers.addItem")}
                </Button>
                <Form.Select
                  value={item.itemName}
                  onChange={(e) => itemChange("itemName", e.target.value)}
                >
                  <option value={0}>
                    {t("dashboard.economyUsers.choose")}
                  </option>
                  {items
                    ?.filter(
                      (item) =>
                        !inventory?.some(
                          (iItem) => iItem.itemName === item.name
                        )
                    )
                    ?.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                </Form.Select>
                <Form.Control
                  style={{ maxWidth: "60px" }}
                  size="sm"
                  type="number"
                  placeholder="1"
                  max={999}
                  min={1}
                  value={item.quantity}
                  onChange={(e) =>
                    itemChange("quantity", parseInt(e.target.value))
                  }
                />
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            {t("modal.close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => showModal(updateEconomyUser)}
          >
            {t("modal.save")}
          </Button>
          <Button variant="danger" onClick={() => showModal(deleteEconomyUser)}>
            {t("modal.delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EconomyUserModal;
